import React from "react";
import LoginPage from "./login-page";
import ConfirmationPage from "./confirm-page";
import ResetPassword from "./reset-password";

interface LoginProps {
  isMemberConfirmation: boolean;
  isLoginPage: boolean;
  isResetPassword: boolean;
}

const Login: React.FC<LoginProps> = ({ isMemberConfirmation, isLoginPage, isResetPassword }) => {
  return (
    <div className="panel">
      {isMemberConfirmation && <ConfirmationPage></ConfirmationPage>}
      {isLoginPage && <LoginPage></LoginPage>}
      {isResetPassword && <ResetPassword></ResetPassword>}
    </div>
  );
};

export default Login;
