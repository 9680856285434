import React, { useEffect, useState } from "react";
import { useMemberStore } from "../../../app/member-store/context";
import { setMember } from "../../../app/member-store/reducer";
import { loginMember, passwordForgotten } from "../../../tide-api";
import Link from "../../link";
import { useI18next } from "gatsby-plugin-react-i18next";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { MemberInfo } from "@qite/tide-client/build/types";
import { decodeTokenToMemberInfo } from "../../../utils";
import { decode } from "punycode";

interface LoginPageProps {}

interface TouchedFields {
  password?: boolean;
  username?: boolean;
}

interface FormFields {
  password: string;
  username: string;
}

export interface MemberInfoPayload extends JwtPayload {
  company: string;
  language: string;
  member: string;
  unique_name: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
}

const LoginPage: React.FC<LoginPageProps> = () => {
  const [memberState, memberDispatch] = useMemberStore();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
  const [newPasswordRequested, setNewPasswordRequested] = useState<boolean>(false);
  const [touched, setTouched] = useState<TouchedFields>();
  const [formValues, setFormValues] = useState<FormFields>({
    password: "",
    username: "",
  });
  const { t, navigate } = useI18next();

  useEffect(() => {
    if (typeof sessionStorage !== "undefined") {
      const sessionToken = sessionStorage.getItem("token");
      if (sessionToken) {
        const member = decodeTokenToMemberInfo(sessionToken);
        if (member) {
          setIsLoggedIn(true);
        } else {
          sessionStorage.removeItem("token");
        }
      }
    }
  }, []);

  const [errors, setErrors] = useState({} as any);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched({ username: false, password: false });
    }
    setTouched({
      ...touched,
      [event.target.name]: true,
    });

    validate();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

    validate();
  };

  const validate = () => {
    let errors = {} as any;
    if (formValues) {
      if (!isForgotPassword) {
        if (!formValues.password || formValues.password.length === 0) {
          errors = {
            ...errors,
            password: true,
          };
        }
      }

      if (!formValues.username || formValues.username.length === 0) {
        errors = {
          ...errors,
          username: true,
        };
      }
    }

    setErrors(errors);
  };

  const handleLogin = async () => {
    try {
      const response = await loginMember(formValues.username, formValues.password);
      const member = decodeTokenToMemberInfo(response.token);
      if (member && member.id !== 0) {
        if (memberDispatch) {
          memberDispatch(setMember(member));
        }
        if (typeof sessionStorage !== "undefined") {
          sessionStorage.setItem("token", response.token);
        }
        setIsLoggedIn(true);
        navigate("/dossiers");
      }
    } catch (error: any) {
      setErrors({
        ...errors,
        api: true,
      });
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await passwordForgotten(formValues.username);
      if (response) {
        setNewPasswordRequested(true);
      }
    } catch (error: any) {
      setErrors({
        ...errors,
        api: true,
      });
    }
  };

  useEffect(() => {
    validate();
  }, [formValues]);

  const showErrors = () => {
    return (
      (errors.password && touched?.password) || (errors.username && touched?.username) || errors.api
    );
  };

  return (
    <>
      {!isLoggedIn && !isForgotPassword && (
        <div className="panel__content">
          <div className="panel__copy">
            <h1 className="panel__copy-heading">{t("PARTNER_LOGIN")}</h1>
            <p className="panel__copy-text">{t("LOGIN_HERE")}</p>
          </div>
          <form className="form" name="form--login" id="form--login">
            <div className="form__region">
              <div className="form__row">
                <div
                  className={`form__group form__group--sm-50 ${
                    touched?.username && errors.username ? "form__group--error" : ""
                  }`}
                >
                  <label className="form__label">{t("USERNAME")} *</label>
                  <input
                    type="text"
                    className="form__input"
                    name="username"
                    value={formValues.username}
                    onChange={(event) => onChange(event)}
                    onBlur={(event) => handleBlur(event)}
                    placeholder={t("USERNAME")}
                    required
                  />
                </div>
                <div
                  className={`form__group form__group--sm-50 ${
                    touched?.password && errors.password ? "form__group--error" : ""
                  }`}
                >
                  <label className="form__label">{t("PASSWORD")} *</label>
                  <input
                    type="password"
                    className="form__input"
                    name="password"
                    value={formValues.password}
                    onChange={(event) => onChange(event)}
                    onBlur={(event) => handleBlur(event)}
                    placeholder={t("PASSWORD")}
                    required
                  />
                </div>
              </div>
            </div>
            {Object.keys(errors).length > 0 && touched && showErrors() && (
              <div className="form__region form__region--errors">
                <div className="form__row">
                  <div className="form__group">
                    <p className="form__error-heading">{t("INVALID_FIELDS")}</p>
                    <ul className="list">
                      {errors.username && touched?.username && (
                        <li className="list__item">{t("USERNAME_REQUIRED")}</li>
                      )}
                      {errors.password && touched?.password && (
                        <li className="list__item">{t("PASSWORD_REQUIRED")}</li>
                      )}
                      {errors.api && (
                        <li className="list__item">{t("DUBBLE_CHECK_USERNAME_OR_PASSWORD")}</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <div className="form__region">
              <div className="form__row form__row--actions">
                <div className="form__group">
                  <button
                    disabled={Object.keys(errors).length > 0}
                    type="button"
                    title={t("REGISTER")}
                    className="cta cta--submit"
                    onClick={() => handleLogin()}
                  >
                    {t("REGISTER")}
                  </button>
                </div>
              </div>
              <div className="form__row form__row--additional-actions">
                <div className="form__group">
                  <a
                    title={t("FORGOT_PASSWORD")}
                    className="link"
                    onClick={() => setIsForgotPassword(true)}
                  >
                    {t("FORGOT_PASSWORD")}
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {isLoggedIn && memberState?.member && !isForgotPassword && (
        <div className="panel panel--success">
          <div className="panel__content">
            <div className="panel__copy">
              <h1 className="panel__copy-heading">
                {t("WELCOME_X", { name: memberState?.member?.name })}
              </h1>
              <p className="panel__copy-text">{t("CLICK_TO_GO_BACK_TO_HOMEPAGE")}</p>
              <Link href="/" internal title={t("BACK_TO_HOMEPAGE")} className="cta">
                {t("BACK_TO_HOMEPAGE")}
              </Link>
            </div>
          </div>
        </div>
      )}
      {isForgotPassword && (
        <>
          {!newPasswordRequested && (
            <div className="panel__content">
              <div className="panel__copy">
                <h1 className="panel__copy-heading">{t("FORGOT_PASSWORD")}</h1>
                <p className="panel__copy-text">
                  {t("ENTER_USERNAME_BELOW")}
                  <br />
                  {t("YOU_RECEIVE_AN_EMAIL_TO_RECREATE_PASSWORD")}
                </p>
              </div>
              <form className="form" name="form--offer-request" id="form--offer-request">
                <div className="form__region">
                  <div className="form__row">
                    <div
                      className={`form__group ${
                        touched?.username && errors.username ? "form__group--error" : ""
                      }`}
                    >
                      <label className="form__label">{t("USERNAME")} *</label>
                      <input
                        type="text"
                        className="form__input"
                        name="username"
                        value={formValues.username}
                        onChange={(event) => onChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        placeholder={t("USERNAME")}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form__region">
                  <div className="form__row form__row--actions">
                    <div className="form__group">
                      <button
                        disabled={errors.username}
                        type="button"
                        title="Login"
                        className="cta"
                        onClick={() => handleForgotPassword()}
                      >
                        {t("REQUEST_RESET")}
                      </button>
                    </div>
                  </div>
                  <div className="form__row form__row--additional-actions">
                    <div className="form__group">
                      <Link
                        title={t("REGISTER")}
                        className="link"
                        onClick={() => setIsForgotPassword(false)}
                      >
                        {t("REGISTER")}
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {newPasswordRequested && (
            <div className="panel panel--success">
              <div className="panel__content">
                <div className="panel__copy">
                  <h1 className="panel__copy-heading">{t("RESET_REQUESTED")}</h1>
                  <p className="panel__copy-text">{t("REQUEST_RECEIVED_SUCCESSFULL")}</p>
                  <Link href="/" title={t("BACK_TO_HOMEPAGE")} className="cta">
                    {t("BACK_TO_HOMEPAGE")}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LoginPage;
