import React, { useEffect, useState } from "react";
import { confirmMember } from "../../../tide-api";
import Link from "../../link";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ConfirmationPageProps {}

interface TouchedFields {
  password?: boolean;
  repeatPassword?: boolean;
}

interface FormFields {
  password: string;
  repeatPassword: string;
}

const ConfirmationPage: React.FC<ConfirmationPageProps> = () => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [touched, setTouched] = useState<TouchedFields>();
  const [formValues, setFormValues] = useState<FormFields>({
    password: "",
    repeatPassword: "",
  });
  const [errors, setErrors] = useState({} as any);
  const [token, setToken] = useState<string | null>(null);
  const { t } = useI18next();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setToken(new URLSearchParams(window.location.search).get("t"));
    }
  }, []);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched({ password: false, repeatPassword: false });
    }
    setTouched({
      ...touched,
      [event.target.name]: true,
    });

    validate();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

    validate();
  };

  const validate = () => {
    let errors = {} as any;
    if (formValues) {
      if (!formValues.password || formValues.password.length === 0) {
        errors = {
          ...errors,
          password: true,
        };
      }
      if (!formValues.repeatPassword || formValues.repeatPassword.length === 0) {
        errors = {
          ...errors,
          repeatPassword: true,
        };
      } else {
        if (formValues.password !== formValues.repeatPassword) {
          errors = {
            ...errors,
            passwordMatch: true,
          };
        }
      }
      if (!token || token.length === 0) {
        errors = {
          ...errors,
          token: true,
        };
      }
    }

    setErrors(errors);
  };

  const handleConfirmation = async () => {
    if (token) {
      try {
        const response = await confirmMember(token, formValues.password, true);
        if (response.ok) {
          setIsConfirmed(true);
        }
      } catch {
        setErrors({
          ...errors,
          api: true,
        });
      }
    }
  };

  useEffect(() => {
    validate();
  }, [formValues, token]);

  const showErrors = () => {
    return (
      (errors.password && touched?.password) ||
      (errors.repeatPassword && touched?.repeatPassword) ||
      errors.token ||
      errors.api
    );
  };

  return (
    <>
      {!isConfirmed && (
        <div className="panel__content">
          <div className="panel__copy">
            <h1 className="panel__copy-heading">{t("CONFIRM_ACCOUNT")}</h1>
          </div>
          <form className="form" name="form--login" id="form--login">
            <div className="form__region">
              <div className="form__row">
                <div
                  className={`form__group form__group--sm-50 ${
                    touched?.password && errors.password ? "form__group--error" : ""
                  }`}
                >
                  <label className="form__label">{t("PASSWORD")} *</label>
                  <input
                    type="password"
                    className="form__input"
                    name="password"
                    value={formValues.password}
                    onChange={(event) => onChange(event)}
                    onBlur={(event) => handleBlur(event)}
                    placeholder={t("PASSWORD")}
                    required
                  />
                </div>
                <div
                  className={`form__group form__group--sm-50 ${
                    touched?.repeatPassword && errors.repeatPassword ? "form__group--error" : ""
                  }`}
                >
                  <label className="form__label">{t("REPEAT_PASSWORD")} *</label>
                  <input
                    type="password"
                    className="form__input"
                    name="repeatPassword"
                    value={formValues.repeatPassword}
                    onChange={(event) => onChange(event)}
                    onBlur={(event) => handleBlur(event)}
                    placeholder={t("REPEAT_PASSWORD")}
                    required
                  />
                </div>
              </div>
            </div>

            {Object.keys(errors).length > 0 && showErrors() && (
              <div className="form__region form__region--errors">
                <div className="form__row">
                  <div className="form__group">
                    <p className="form__error-heading">{t("REVIEW_FIELDS")}</p>
                    <ul className="list">
                      {errors.password && touched?.password && (
                        <li className="list__item">{t("PASSWORD_REQUIRED")}</li>
                      )}
                      {errors.repeatPassword && touched?.repeatPassword && (
                        <li className="list__item">{t("PASSWORD_REQUIRED")}</li>
                      )}
                      {errors.token && <li className="list__item">{t("TOKEN_INVALID")}</li>}
                      {errors.api && <li className="list__item">{t("NO_ACCESS")}</li>}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            <div className="form__region">
              <div className="form__row form__row--actions">
                <div className="form__group">
                  <button
                    disabled={Object.keys(errors).length > 0}
                    type="button"
                    title={t("CONFIRM_ACCOUNT")}
                    className="cta cta--submit"
                    onClick={() => handleConfirmation()}
                  >
                    {t("CONFIRM_ACCOUNT")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {isConfirmed && (
        <div className="panel panel--success">
          <div className="panel__content">
            <div className="panel__copy">
              <h1 className="panel__copy-heading">{t("RECEIVED_REQUEST")}</h1>
              <p className="panel__copy-text">{t("ACCOUNT_ACTIVATED_LOGIN")}</p>
              <Link href="/login" internal title={t("GO_TO_LOGIN")} className="cta">
                {t("GO_TO_LOGIN")}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationPage;
