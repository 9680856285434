import React, { useContext, useEffect, useState } from "react";
import { newPassword } from "../../../tide-api";
import Icon from "../../icon";
import Link from "../../link";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ResetPasswordProps {}

interface TouchedFields {
  password?: boolean;
  repeatPassword?: boolean;
}

interface FormFields {
  password: string;
  repeatPassword: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const [passwordSet, setPasswordSet] = useState<boolean>(false);
  const [touched, setTouched] = useState<TouchedFields>();
  const [formValues, setFormValues] = useState<FormFields>({
    password: "",
    repeatPassword: "",
  });
  const [errors, setErrors] = useState({} as any);
  const [token, setToken] = useState<string | null>(null);
  const { t } = useI18next();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setToken(new URLSearchParams(window.location.search).get("t"));
    }
  }, []);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched({ password: false, repeatPassword: false });
    }
    setTouched({
      ...touched,
      [event.target.name]: true,
    });

    validate();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

    validate();
  };

  const validate = () => {
    let errors = {} as any;
    if (formValues) {
      if (!formValues.password || formValues.password.length === 0) {
        errors = {
          ...errors,
          password: true,
        };
      }
      if (!formValues.repeatPassword || formValues.repeatPassword.length === 0) {
        errors = {
          ...errors,
          repeatPassword: true,
        };
      } else {
        if (formValues.password !== formValues.repeatPassword) {
          errors = {
            ...errors,
            matchPassword: true,
          };
        }
      }

      if (!token || token.length === 0) {
        errors = {
          ...errors,
          token: true,
        };
      }
    }

    setErrors(errors);
  };

  const handleResetPassword = async () => {
    if (token) {
      try {
        const response = await newPassword(token, formValues.password);
        if (response) {
          setPasswordSet(true);
        }
      } catch {
        setErrors({
          ...errors,
          api: true,
        });
      }
    }
  };

  useEffect(() => {
    validate();
  }, [formValues, token]);

  const showErrors = () => {
    return (
      (errors.password && touched?.password) ||
      (errors.repeatPassword && touched?.repeatPassword) ||
      errors.token ||
      errors.api
    );
  };

  return (
    <>
      {!passwordSet && (
        <div className="panel__content">
          <div className="panel__copy">
            <h1 className="panel__copy-heading">{t("INSERT_NEW_PASSWORD")}</h1>
            <p className="panel__copy-text">
              {t("INSERT_NEW_PASSWORD_BELOW")}
              <br /> <strong>{t("SOME_TIPS")}</strong>
            </p>
            <ul className="list">
              <li className="list__item">{t("TIP_1")}</li>
              <li className="list__item">{t("TIP_2")}</li>
              <li className="list__item">{t("TIP_3")}</li>
              <li className="list__item">{t("TIP_4")}</li>
              <li className="list__item">{t("TIP_5")}</li>
            </ul>
          </div>
          <form className="form" name="form--offer-request" id="form--offer-request">
            <div className="form__region">
              <div className="form__row">
                <div
                  className={`form__group form__group--sm-50 ${
                    touched?.password && errors.password ? "form__group--error" : ""
                  }`}
                >
                  <label className="form__label">{t("NEW_PASSWORD")} *</label>
                  <input
                    type="password"
                    className="form__input"
                    name="password"
                    value={formValues.password}
                    onChange={(event) => onChange(event)}
                    onBlur={(event) => handleBlur(event)}
                    placeholder={t("NEW_PASSWORD")}
                    required
                  />
                </div>
                <div
                  className={`form__group form__group--sm-50 ${
                    touched?.password && errors.password ? "form__group--error" : ""
                  }`}
                >
                  <label className="form__label">{t("REPEAT_NEW_PASSWORD")} *</label>
                  <input
                    type="password"
                    className="form__input"
                    name="repeatPassword"
                    value={formValues.repeatPassword}
                    onChange={(event) => onChange(event)}
                    onBlur={(event) => handleBlur(event)}
                    placeholder={t("REPEAT_NEW_PASSWORD")}
                    required
                  />
                </div>
              </div>
            </div>
            {Object.keys(errors).length > 0 && touched && showErrors() && (
              <div className="form__region form__region--errors">
                <div className="form__row">
                  <div className="form__group">
                    <p className="form__error-heading">{t("INVALID_FIELDS")}</p>
                    <ul className="list">
                      {errors.password && touched?.password && (
                        <li className="list__item">{t("PASSWORD_REQUIRED")}</li>
                      )}
                      {errors.repeatPassword && touched?.repeatPassword && (
                        <li className="list__item">{t("REPEAT_PASSWORD_REQUIRED")}</li>
                      )}
                      {errors.api && <li className="list__item">{t("NO_ACCESS")}</li>}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <div className="form__region">
              <div className="form__row form__row--actions">
                <div className="form__group">
                  <button
                    disabled={Object.keys(errors).length > 0}
                    type="button"
                    title={t("CONFIGURE_NEW_PASSWORD")}
                    className="cta"
                    onClick={() => handleResetPassword()}
                  >
                    {t("CONFIGURE_NEW_PASSWORD")}
                  </button>
                </div>
              </div>
              <div className="form__row form__row--additional-actions">
                <div className="form__group">
                  <Link href="/login" internal title={t("BACK_TO_LOGIN")} className="link">
                    {t("BACK_TO_LOGIN")}
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {passwordSet && (
        <div className="panel panel--success">
          <div className="panel__content">
            <div className="panel__copy">
              <h1 className="panel__copy-heading">{t("PASSWORD_UPDATED")}</h1>
              <p className="panel__copy-text">{t("PASSWORD_UPDATED_DESCRIPTION")}</p>
              <Link href="/login" internal title={t("GO_TO_LOGIN")} className="cta">
                {t("GO_TO_LOGIN")}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
