import {
  TideItemForDestinations,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForSearchPage,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";

import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import React from "react";
import { getChildNavigationLinks } from "../utils";
import { graphql } from "gatsby";
import StickyBar from "../components/sticky-bar";
import Link from "../components/link";
import Icon from "../components/icon";
import Login from "../components/login";
import { first, isEmpty } from "lodash";
import NotificationBar from "../components/notification-bar";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface LoginPageTemplateProps {
  data: LoginPageData;
  pageContext: {
    isLoginPage: boolean;
    isMemberConfirmation: boolean;
    isResetPassword: boolean;
    title: string;
  };
}

const LoginPageTemplate: React.FC<LoginPageTemplateProps> = ({
  data,
  pageContext: { isLoginPage, isMemberConfirmation, isResetPassword, title },
}) => {
  const { t } = useI18next();
  const notificationBar = first(data.notifications?.nodes);
  return (
    <Layout>
      <Helmet title={title} />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      <StickyBar
        navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
        actionsComponent={
          data.searchPage && (
            <Link
              href={`/${data.searchPage.content?.general?.slug}`}
              internal
              title={t("SEARCH_AND_BOOK")}
              className="cta cta--icon"
            >
              <Icon name="ui-search" />
              <Trans>SEARCH_AND_BOOK</Trans>
            </Link>
          )
        }
      />
      <Login
        isLoginPage={isLoginPage}
        isMemberConfirmation={isMemberConfirmation}
        isResetPassword={isResetPassword}
      ></Login>
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
    </Layout>
  );
};

interface LoginPageData extends LookupQueryData {
  searchPage: TideItemForSearchPage;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query LoginPageQuery($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchPage: tideItemForSearchPage(language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }
`;

export default LoginPageTemplate;
